export default {
  okText: '確認',
  closeText: '閉じる',
  cancelText: 'キャンセル',
  loadingText: 'ロード中...',
  saveText: '保存',
  delText: '削除',
  resetText: 'リセット',
  searchText: '検索',
  queryText: '今すぐ検索',
  nextText: '次',

  inputText: '入力してください',
  chooseText: '選択してください',

  add: '追加',
  update: '更新',
  refresh: '更新',
  back: '戻る',

  light: '明るいテーマ',
  dark: '暗いテーマ',
  countdown: {
    normalText: '認証コード取得',
    sendText: '{0}秒後もう一度取得',
  },
};
